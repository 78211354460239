import React from "react";
import PropTypes from "prop-types";
import "./LabelWithAdditionalText.scss";
import { Tooltip } from "components/shared/Tooltip";


const LabelWithAdditionalText = ({ title, addionalLabel, value, rightGap, tooltipHtml, name }) => {
    return (
        <label className="labelWithText">
            {!!title && <span>
                <span className="labelWithText__title">{title}</span>
                {tooltipHtml && (
                    <Tooltip id={"labelwithadditional_" + name}>{tooltipHtml}</Tooltip>
                )}
            </span>}
            {!!addionalLabel && <span className={`labelWithText__txt ${rightGap ? "labelWithText__txt--icon " : " "}`}>
                <span className="labelWithText__additional">{addionalLabel}</span>
                <span className="labelWithText__value">{value}</span>
            </span>}
        </label>
    )
}

LabelWithAdditionalText.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    addionalLabel: PropTypes.string,
};

LabelWithAdditionalText.defaultProps = {
    title: 'Title',
    value: '',
    addionalLabel: '',
    key: "unique"
};


export default LabelWithAdditionalText;