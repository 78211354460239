import React, { useState, useEffect } from "react";
// Prop types
import PropTypes from "prop-types";
// Css
import "./GenAiButton.scss";
import GenAIIcon from "assets/images/Gen_AI.gif";
import Image from "components/shared/Image";
import GLOBAL_CONSTANTS from "globalConstants";
import CONFIG from "config";

const GenAiButton = ({ isDisable, totalSuggestion, isDescInput, genAITitles, updateTitle, genAIDescs, updateDesc, handleChange, isLoading }) => {
  const [timer, setTimer] = useState(null);
  const [localIndex, setLocalIndex] = useState(0);

  const handleGenAIButtonClick = () => {
    if (genAITitles && genAITitles.length > 0) {
      const nextIndex = (localIndex + 1) % genAITitles.length;
      //Only state update no api call
      updateTitle(genAITitles[nextIndex], nextIndex);
      setLocalIndex(nextIndex);

      if (timer) {
        clearTimeout(timer);
      }
      const newTimer = setTimeout(() => {
        if (!isLoading) {
          // Calling KP Post API
          handleChange(genAITitles[nextIndex], "title", GLOBAL_CONSTANTS.STEP1, null, true, true);
        }
      }, CONFIG.META_GENAI_UPDATE_DELAY);
      setTimer(newTimer);
    }

    if (genAIDescs && genAIDescs.length > 0) {
      const nextIndex = (localIndex + 1) % genAIDescs.length;
      updateDesc(genAIDescs[nextIndex], nextIndex);
      setLocalIndex(nextIndex);

      if (timer) {
        clearTimeout(timer);
      }

      const newTimer = setTimeout(() => {
        if (!isLoading) {
          handleChange(genAIDescs[nextIndex], "description", GLOBAL_CONSTANTS.STEP1, null, true, true);
        }
      }, CONFIG.META_GENAI_UPDATE_DELAY);
      setTimer(newTimer);
    }

  };

  useEffect(() => {
    if (isLoading && timer) {
      clearTimeout(timer);
      setTimer(null);
    }
  }, [isLoading, timer]);

  return (
    <button className={`genaibutton ${isDescInput ? 'genaibutton__description' : ''} ${isDisable ? 'disable' : ''}`} title={isDescInput ? "Generate Description" : "Generate Title"} onClick={handleGenAIButtonClick}>
      <Image src={GenAIIcon} alt="genai icon" />
      <div className="genaibutton__counterText">{localIndex + 1} / {totalSuggestion}</div>
    </button>
  )
};

// GenAiButton Prop types
GenAiButton.propTypes = {
  isDisable: PropTypes.bool,
  isDescInput: PropTypes.bool,
  totalSuggestion: PropTypes.number,
  currentIndex: PropTypes.number,
  genAITitles: PropTypes.array,
  updateTitle: PropTypes.func,
  handleChange: PropTypes.func,
  genAIDescs: PropTypes.array,
  updateDesc: PropTypes.func
};

// Export GenAiButton as default
export default GenAiButton;


