import { GEN_AI } from 'redux/constants';
export const name = 'genai';
export const initialState = {
  metagenAIData: [],
  successfulFileIds: [],
  error: '',
  loading: false
};
export const selectors = {
  getGenAIData: state => state[name].metagenAIData,
  getGenAiLoading: state => state[name].loading,
};

const initGenaiKpApI = (state) => {
  return {
    ...state,
    loading: true
  };
}

const setKpMetagenAIData = (state, payload) => {
  return {
    ...state,
    metagenAIData: payload,
  }
};

const setKpMetagenAIProgress = (state, payload) => {
  return {
    ...state,
    metagenAIData: [],
    loading: true
  }
};

const setShowGenAIIcons = (state, payload) => {
  return {
    ...state,
    loading: payload
  }
};

const setSuccessfulFileId = (state, payload) => {
  return {
    ...state,
    successfulFileIds: [...state.successfulFileIds, { fileId: payload.fileId, fileName: payload.fileName, title: payload.title, description: payload.description }]
  }
};

const setKpMetagenAIFailure = (state) => {
  return {
    ...state,
    loading: true
  };
};

export function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GEN_AI.INIT_GENAI_KP_API:
      return initGenaiKpApI(state);
    case GEN_AI.KP_METAGENAI_SUCCESS:
      return setKpMetagenAIData(state, payload);
    case GEN_AI.KP_METAGENAI_PROGRESS:
      return setKpMetagenAIProgress(state, payload);
    case GEN_AI.SET_SHOW_GEN_AI_ICON:
      return setShowGenAIIcons(state, payload);
    case GEN_AI.ADD_SUCCESSFUL_FILE_ID:
      return setSuccessfulFileId(state, payload);
    case GEN_AI.KP_METAGEN_AI_FAILURE:
      return setKpMetagenAIFailure(state);
    default:
      return state;
  }
}
