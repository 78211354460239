import React, { useState } from "react";
import "./ProposalBioSlide.scss";
import LABELS from "labels";
import { BulletEditor } from "components/BulletEditor/BulletEditor";
import { replaceInLabel } from 'utils/helpers/helpers';


const ProposalBioSlide = ({ peopleData }) => {
	const [isEdit, setEdit] = useState(false);
	const {
		PROPOSAL_BIO: {
			ROLE,
			SUMMARY_RELEVANT_EXPERIENCE,
			RELEVANT_EXPERIENCE,
			PRIOR_EXPERIENCE_EDUCATION,
			PROFILE_SUMMARY,
			DEFAULT_MESSAGE,
			COPY_RIGHT,
		},
	} = LABELS;

	if (!peopleData) {
		return <p>{DEFAULT_MESSAGE}</p>;
	}

	const { picture, preferredFirstName, preferredLastName, businessTitle, hostOfficeLocation } =
		peopleData;
	const currentYear = new Date().getFullYear();

	return (
		<div className="proposalbio">
			<div className="proposalbio__profile_section">
				<div className="proposalbio__image_container">
					<img src={picture} alt="Profile Image" className="proposalbio__image" />
				</div>
				<h2 className="proposalbio__name">{preferredFirstName} {preferredLastName}</h2>
				<div className="proposalbio__position">{businessTitle}</div>
				<div className="proposalbio__location">{hostOfficeLocation.city}</div>

				<div className="proposalbio__profile_section__label">
					<BulletEditor
						baseHtml={`<ul><li>Enter text here.</li></ul>`}
						maximumLines={4}
						oneLineHeight={21}
						id="role"
						heading={ROLE}
						nonGreenHeading
						callbackEdit={setEdit}
						disableEdit={isEdit}
					/>
				</div>

				<div className="proposalbio__profile_section__label">
					<BulletEditor
						baseHtml={`<ul><li>Enter text here.</li></ul>`}
						maximumLines={5}
						oneLineHeight={21}
						id="summary"
						heading={SUMMARY_RELEVANT_EXPERIENCE}
						nonGreenHeading
						callbackEdit={setEdit}
						disableEdit={isEdit}
					/>
				</div>
				<p className="proposalbio__source">Source: BCG</p>
			</div>

			<div className="proposalbio__details_section">
				<div className="proposalbio__details_section__label">
					<BulletEditor
						baseHtml={`<p>Enter text here.</p>`}
						maximumLines={6}
						oneLineHeight={21}
						toolbarHidden
						id="profilesummary"
						heading={PROFILE_SUMMARY}
						callbackEdit={setEdit}
						disableEdit={isEdit}
					/>
				</div>
				<div className="proposalbio__details_section__label">
					<BulletEditor
						baseHtml={`<ul><li>Enter text here.</li></ul>`}
						maximumLines={16}
						oneLineHeight={21}
						id="relevantexperience"
						heading={RELEVANT_EXPERIENCE}
						callbackEdit={setEdit}
						disableEdit={isEdit}
					/>
				</div>
				<div className="proposalbio__details_section__label">
					<BulletEditor
						baseHtml={`<ul><li>Enter text here.</li></ul>`}
						maximumLines={5}
						oneLineHeight={21}
						id="experienceeducation"
						heading={PRIOR_EXPERIENCE_EDUCATION}
						callbackEdit={setEdit}
						disableEdit={isEdit}
					/>
				</div>
				<p className="proposalbio__copyright">{replaceInLabel(COPY_RIGHT, "{year}", currentYear)}</p>
			</div>
		</div>
	);
};

export default ProposalBioSlide;
