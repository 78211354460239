import {
  GEN_AI
} from 'redux/constants';
import { doAxiosRequest } from 'config-axios';
import CONFIG from 'config';
import { put, takeLatest, call, delay, select } from '@redux-saga/core/effects';
import { kpMetagenAISuccess, addSuccessfulFileId, kpMetagenAIProgress, setShowGenAIIcon, kpMetagenAIFailure } from './genai.actions';

export function* pollKPmetagenAI(requestId, maxAttempts = CONFIG.MAX_META_GENAI_POLL) {
  try {
    for (let attempts = 0; attempts < maxAttempts; attempts++) {
      const metagenAIResponse = yield call(doAxiosRequest, {
        method: 'GET',
        endpoint: CONFIG.API_URL.GET_META_GENAI(requestId),
        headers: {
          'x-api-key': CONFIG.XA
        }
      });
      const status = metagenAIResponse?.status;

      if (status === 'COMPLETED') {
        yield put(kpMetagenAISuccess(metagenAIResponse));
        return;
      } else if (status === 'INPROGRESS' || status === 'REQUESTED') {
        yield put(kpMetagenAIProgress(metagenAIResponse));
        yield delay(CONFIG.META_GENAI_NEXT_POLL_DELAY);
      } else {
        yield put(kpMetagenAIFailure(metagenAIResponse));
        return;
      }
    }

    // If max attempts reached and still in progress
    yield put(kpMetagenAIFailure({ message: 'Max attempts reached for KPmetagenAI polling' }));
  } catch (error) {
    yield put(kpMetagenAIFailure(error));
  }
}

function* initGenaiKpApI(action) {
  const { requestId, fileName } = action.payload;
  const allowedFileTypes = CONFIG.ALLOWED_FILE_EXTNS_METAGENAI;
  const fileExtension = fileName.split('.').pop().toLowerCase();

  if (allowedFileTypes.includes(fileExtension)) {
    yield call(pollKPmetagenAI, requestId);
  }
  else {
    yield put(setShowGenAIIcon(false));
  }
}

export default function* GenaiSaga() {
  try {
    yield takeLatest(GEN_AI.INIT_GENAI_KP_API, initGenaiKpApI);
  } catch (error) {
    yield put(kpMetagenAIFailure(error, GenaiSaga))
  }
}